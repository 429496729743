 
<template>
  <b-card>
   <b> TOP 5 USERS BY CREDIT/ ORDERS TODAY </b>
  <b-row>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group
        class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >


    </b-col>
    <b-col
      md="6"
      class="my-1"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
  </b-card>
</template>

<script>
import { BTabs, BTab, BCard,BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, } from 'bootstrap-vue'
import ServeiceAPI from "@/services/services.service.js";
import { serverUrl } from '@/global/variables';


export default {
  components: {
    BTabs, BTab, BCard,
      BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
           perPage: 5,
      pageOptions: [5, 10, 25, 50],
    //  totalRows: 1,
      currentPage: 1,
      sortBy: 'credit',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],

        fields: [
       //   { key: 'id', sortable: true },
          { key: 'username', sortable: true },
          { key: 'credit', sortable: true },
          { key: 'orders', sortable: true },
        ],

      items:[]
    }
  },

 mounted() {
 const requestOptions = {
    method: "GET",
  //  headers: { "Content-Type": "application/json" },
    headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }, 
  };

     fetch(serverUrl("/api/analytics/topUsers"), requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.items = data;
        console.log( data);
        // Set the initial number of items in table
    this.totalRows = this.items.length
      });
  },
}
</script>
 
