<template>
  <div class="home-admin mt-2">
    <div class="info-details">
      <CardInfo
        v-for="data of info"
        :key="data.label"
        :label="data.label"
        :content="data.value"
        :icon="data.icon"
        :loading="loading"
        :to="data.to"
        :color="data.color"
      />
    </div>
    <div class="reports-help">
      <b-row class="mb-1">
        <!-- Order history Line chart -->
        <b-col lg="6" class="wrap-height">
          <LineChart
            label="Orderes"
            :loading="orderMonth.loading"
            :values="Object.values(orderMonth.value)"
            :total="totalOrders"
          />
        </b-col>
        <b-col lg="6" class="wrap-height">
          <Top20Credit />
        </b-col>
      </b-row>
      <!-- Services report -->
      <ServiceReports />
    </div>
  </div>
</template>

<script>
import CardInfo from "@/components/UI/CardInfo.vue";
import Analytics from "@/services/analytics.service.js";
import Top20Credit from "@/components/reports/Top20Credit.vue";
import ServiceReports from "@/components/reports/ServiceReports.vue";
import LineChart from "@/components/charts/LineChart.vue";
import { BRow, BCol } from "bootstrap-vue";
export default {
  data: () => ({
    loading: false,
    info: [
      {
        label: "Customers",
        value: "1203",
        icon: "UsersIcon",
        to: "/admin/user",
        color: "#00cfe8",
        serverKey: "users",
      },
      {
        label: "Earnings",
        value: "5345 $",
        icon: "DollarSignIcon",
        color: "#82868b",
        serverKey: "earnings",
      },
      {
        label: "Services",
        value: "145",
        icon: "LayersIcon",
        to: "/admin/services",
        color: "#ff9f43",
        serverKey: "services",
      },
      {
        label: "Success Orders",
        value: "5345",
        icon: "ListIcon",
        to: "/admin/orders",
        color: "#28c76f",
        serverKey: "success_orders",
      },
      {
        label: "Orders In Process",
        value: "5345",
        icon: "ListIcon",
        to: "/admin/orders",
        serverKey: "process_orders",
      },
      {
        label: "Rejected Orders",
        value: "145",
        icon: "SlashIcon",
        to: "/admin/orders",
        color: "#ea5455",
        serverKey: "failed_orders",
      },
    ],
    orderMonth: {
      loading: false,
      value: [],
    },
  }),
  methods: {
    async fetchInfoData() {
      this.loading = true;
      try {
        const data = await Analytics.dashboardAdmin();
        Object.keys(data).forEach((info) => {
          const idx = this.info.findIndex((d) => d.serverKey == info);
          if (idx > -1) this.info[idx].value = `${data[info]}`;
          if (info == "earnings")
            this.info[idx].value = `${this.info[idx].value} $`;
        });
      } catch (e) {
        alert("Something went wrong ..");
      }
      this.loading = false;
    },
    async fetchOrderMonth() {
      this.orderMonth.loading = true;
      this.orderMonth.value = await Analytics.ordersMonth();
      this.orderMonth.loading = false;
    },
  },
  computed: {
    totalOrders() {
      const ordersByMonth = Object.values(this.orderMonth.value);
      if (!ordersByMonth.length) return;
      return ordersByMonth.reduce((a, b) => a + b);
    },
  },
  async mounted() {
    this.fetchInfoData();
    this.fetchOrderMonth();
  },
  components: { BRow, BCol, CardInfo, Top20Credit, ServiceReports, LineChart },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
.info-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 25px;
}
.wrap-height {
  margin-bottom: 20px;
  > * {
    height: 100%;
  }
}
</style>