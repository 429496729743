<template>
  <b-card class="card-info">
    <div class="stiker" :style="stikerStyle">
      <feather-icon :icon="icon" size="30" />
    </div>
    <div class="info-label">
      <h3 v-text="label" />
    </div>
    <div class="info-content">
      <b-skeleton v-if="loading" width="100%" height="25px"></b-skeleton>
      <!-- <b-spinner v-if="loading" variant="primary" style="width: 3rem; height: 3rem" /> -->
      <span v-else>
        {{ content }}
      </span>
    </div>
    <b-button v-if="to" class="view-all" variant="link" size="sm" :to="to">
      <feather-icon icon="EyeIcon" class="mr-50" />
      {{ actionTxt || "View All" }}
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BButton, BSkeleton } from "bootstrap-vue";
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    content: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
    },
    to: {
      type: String,
    },
    actionTxt: {
      type: String,
    },
  },
  computed: {
    stikerStyle() {
      const color = this.color || "#ffc003";
      return {
        "box-shadow": `1px 1px 20px 0px ${color}`,
        "background-color": color,
      };
    },
  },
  components: {
    BCard,
    BButton,
    BSkeleton,
  },
};
</script>

<style lang="scss" scoped>
.card-info {
  position: relative;
  .stiker {
    position: absolute;
    top: -10px;
    left: -10px;
    border-radius: 10px;
    padding: 10px;
    color: white;
    width: 55px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info-label {
    text-align: right;
    h3 {
      font-size: 18px;
      border-bottom: 1px dotted #93939340;
      padding-bottom: 10px;
      padding: 10px;
    }
  }
  .info-content {
    text-align: center;
    font-size: 28px;
    margin: 5px 0;
    font-weight: bold;
  }
  .card-body {
    padding: 10px;
  }
}
</style>