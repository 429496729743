<template>
  <b-card>
    <div class="date-action">
      <flat-pickr
        v-model="rangeDate"
        class="form-control mb-2"
        placeholder="Select Date"
        :config="{ mode: 'range' }"
      />
    </div>
    <b-table
      striped
      :fields="fields"
      :items="finalList"
      :busy="loading"
      responsive="sm"
      class="service-report-table"
    >
      <!-- Loading -->
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(source)="data">
        <b-badge pill variant="light-primary">
          {{ data.item.value }}
        </b-badge>
      </template>
      <template #cell(total_checkes)="data">
        <b-badge
          pill
          :variant="+data.item.total_checkes ? 'light-success' : 'light-danger'"
        >
          {{ data.item.total_checkes }}
        </b-badge>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BTabs, BTab, BCard, BTable, BBadge } from "bootstrap-vue";
import ServeiceAPI from "@/services/services.service.js";
import flatPickr from "vue-flatpickr-component";
export default {
  data: () => ({
    loading: false,
    rangeDate: "",
    fields: [
      "id",
      { key: "name", label: "Name" },
      { key: "price", label: "Charges $" },
      { key: "source", label: "Value" },
      { key: "total_checkes", label: "Total checks" },
    ],
    values: [],
  }),
  mounted() {
    this.fetch();
  },
  watch: {
    rangeDate(v) {
      this.fetch(v);
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.values = await ServeiceAPI.reports(this.rangeDate.split(" to "));
      this.loading = false;
    },
  },
  computed: {
    finalList() {
      return this.values?.map((s) => ({
        ...s,
      // source: { ...s.source, url: new URL(s.source.url) },
      source: { ...s.source, url: '' }, //show name insead of url
      }));
    },
  },
  components: { BTabs, BTab, BCard, BTable, BBadge, flatPickr },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.date-action {
  display: flex;
  justify-content: flex-end;
  > * {
    max-width: 400px;
  }
}
.service-report-table {
  overflow: auto;
}
</style>